import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import sectionPhoto from '../../../img/competenciesSectionBg.svg';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function Competence() {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  useGSAP(() => {

    const competenciesSection = containerRef.current;
    const competenciesBg = document.getElementById("competenciesSiteBg");
    ScrollTrigger.refresh();
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: competenciesSection,
        pin: true,
        pinSpacing: true,
        start: "top top",
        end: "+=800px",
        scrub: 1,
        id: "competencies",
      },
    });
    tl.to(competenciesBg, {
      rotation: 720,
      ease: "none",
    });
  }, {scope: containerRef, revertOnUpdate: true,});
  return (
    <section id="competencies" ref={containerRef}>
      <div className="content">
        <h2 className="title">
          <span className="subTitle">{t('competenceSectionSubTitle')}</span> {t('competenceSectionTitle')}
        </h2>
        <div className="desc">
          <p>{t('competenceSectionParagraphOne')}</p>
          <p>{t('competenceSectionParagraphTwo')}</p>
          <p>{t('competenceSectionParagraphThree')}</p>
        </div>
      </div>
      <img src={sectionPhoto} alt="competencies Site graphic" id="competenciesSiteBg" width={268} height={248} />
    </section>
  )
}