import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function Scroll() {
  const scrollScope = useRef(null);
  const scrollArrowRef = useRef(null);
  const scrollWheelRef = useRef(null);
  
  useEffect(() => {
    const scrollWheel = scrollWheelRef.current;
    scrollWheel.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }, []);

  useGSAP(() => {
    const myTrigger = document.querySelector('#wandachowicz');
    const scrollArrow = scrollArrowRef.current;
    const scrollWheel = scrollWheelRef.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: myTrigger,
        start: "+=50px",
        end: () => "+=" + myTrigger.offsetHeight,
        scrub: 0.1,
      },
    });
    
    tl.to(scrollWheel, { rotation: 180 * 3 });
    tl.add(
      gsap.to(scrollArrow, {
        rotation: 180 * 3
      }),
      0
    );

  }, {scope: scrollScope, revertOnUpdate: true});

  return (
    <div id="scroll" ref={scrollScope}>
      <img src="/img/icons/scrollArrow.svg" alt="Scroll arrow" id="arrow" width={8} height={30} ref={scrollArrowRef}/>
      <img src="/img/icons/scrollBg.svg" alt="Scroll arrow background" id="arrowBg" width={70} height={70} ref={scrollWheelRef}/>
    </div>
  )
}
