import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import './about.scss';
import { Helmet } from "react-helmet-async";
import { Header } from "./header";
import { BottomHooks } from "./bottomHooks";
import { Scroll } from "../scroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function About() {
  const { t } = useTranslation();
  const slideOneRef = useRef(null);
  const slideTwoRef = useRef(null);
  const slideFiveRef = useRef(null);

  useEffect(() => {
    document.body.classList.add('about');
    return () => {
      document.body.classList.remove('about');
    };
  }, []);

  useGSAP(() => {
    const mm = gsap.matchMedia();

    mm.add("(max-width: 650px)", () => {
      gsap.to("h1 .first", {
        opacity: 1,
        duration: 4,
        // y: "-50%",
        scale: 1,
        delay: 1,
        ease: "power3.out",
      });
      gsap.to("h1 .second", {
        opacity: 1,
        scale: 1,
        // y: "-50%",
        duration: 3,
        delay: 4,
        ease: "power3.out"
      });
    });

    mm.add("(min-width: 650.1px)", () => {
      gsap.to("h1 .first", {
        opacity: 1,
        scale: 1.3,
        duration: 4,
        delay: 1,
        ease: "power3.out",
      });
      gsap.to("h1 .second", {
        opacity: 1,
        scale: 1,
        // y: 80,
        duration: 3,
        delay: 4,
        ease: "power3.out"
      });
    });

    return () => {
      mm.revert(); // Usuń media queries po odmontowaniu komponentu
    };
  }, { scope: slideOneRef });
  
  useGSAP(() => {
    const slideTwo = slideTwoRef.current;
    ScrollTrigger.refresh();
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: slideTwo,
        pin: true,
        pinSpacing: true,
        scrub: 0.1,
        start: "top top",
        end: "+=600%",
      },
    });
    // SLIDE TWO
    tl.to("#slideTwoText", {
      opacity: 1,
      scale: 2,
      y: "-50%",
      zIndex: 2,
      duration: 0.5,
      ease: "power3.out"
    });
    tl.to("#slideTwoText", {
      opacity: 0,
      scale: 5,
      y: "-50%",
      zIndex: 1,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    tl.to("#slideTwoImage", {
      opacity: 1,
      scale: 1,
      filter: "saturate(1)",
      zIndex: 2,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    tl.to("#slideTwoImage", {
      opacity: 0,
      scale: 2,
      filter: "saturate(0.1)",
      zIndex: 1,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    
    // SLIDE Three
    tl.to("#slideThreeText", {
      opacity: 1,
      scale: 2,
      y: "-50%",
      zIndex: 2,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    tl.to("#slideThreeText", {
      opacity: 0,
      scale: 5,
      y: "-50%",
      zIndex: 1,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    tl.to("#slideThreeImage", {
      opacity: 1,
      scale: 1,
      filter: "saturate(1)",
      zIndex: 2,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    tl.to("#slideThreeImage", {
      opacity: 0,
      scale: 2,
      filter: "saturate(0.1)",
      zIndex: 1,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    
    // SLIDE Four
    tl.to("#slideFourText", {
      opacity: 1,
      scale: 2,
      y: "-50%",
      zIndex: 2,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    tl.to("#slideFourText", {
      opacity: 0,
      scale: 5,
      y: "-50%",
      zIndex: 1,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    tl.to("#slideFourImage", {
      opacity: 1,
      scale: 1,
      filter: "saturate(1)",
      zIndex: 2,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
    tl.to("#slideFourImage", {
      opacity: 0,
      scale: 2,
      filter: "saturate(0.1)",
      zIndex: 1,
      duration: 0.5,
      ease: "power3.out"
    }, "-=0.1");
  }, {scope: slideTwoRef, revertOnUpdate: true});

  useGSAP(() => {
    const slideFive = slideFiveRef.current;
    ScrollTrigger.refresh();
    const mm = gsap.matchMedia();

    mm.add("(max-width: 650px)", () => {
      const tlFive = gsap.timeline({
        scrollTrigger: {
          trigger: slideFive,
          pin: true,
          pinSpacing: true,
          scrub: 1,
          start: "top",
          end: "+=600%",
        },
      });
      // First
      tlFive.to("#slideFive .first", {
        opacity: 1,
        scale: 1,
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        duration: 2,
        ease: "power3.out"
      });
      tlFive.to("#slideFive .first", {
        opacity: 0,
        scale: 5,
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        duration: 0.5,
        ease: "power3.out"
      }, "-=0.1");
      // Second
      tlFive.to("#white", {
        opacity: 1,
        scale: 1,
        zIndex: 2,
        duration: 6,
        ease: "bounce.in"
      }, "-=0.3", {scrub: 0.2});
      tlFive.to("#white .second", {
        opacity: 1,
        scale: 1,
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        duration: 2,
        ease: "power3.out"
      }, "-=0.1");
    });

    mm.add("(min-width: 650.1px)", () => {
      const tlFive = gsap.timeline({
        scrollTrigger: {
          trigger: slideFive,
          pin: true,
          pinSpacing: true,
          scrub: 1,
          start: "top",
          end: "+=600%",
        },
      });
      // First
      tlFive.to("#slideFive .first", {
        opacity: 1,
        scale: 2,
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        duration: 2,
        ease: "power3.out"
      });
      tlFive.to("#slideFive .first", {
        opacity: 0,
        scale: 5,
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        duration: 0.5,
        ease: "power3.out"
      }, "-=0.1");
      // Second
      tlFive.to("#white", {
        opacity: 1,
        scale: 1,
        zIndex: 2,
        duration: 6,
        ease: "bounce.in"
      }, "-=0.3", {scrub: 0.2});
      tlFive.to("#white .second", {
        opacity: 1,
        scale: 1,
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        duration: 2,
        ease: "power3.out"
      }, "-=0.1");
    });


  }, {scope: slideFiveRef, revertOnUpdate: true});

  return (
    <>
      <Helmet>
        <title>{t('aboutSinglePageSeoTitle')}</title>
        <meta name="description" content={t('aboutSinglePageSeoDescription')} />
        <meta property="og:title" content={t('aboutSinglePageSeoTitle')} />
        <meta property="og:description" content={t('aboutSinglePageSeoDescription')} />
      </Helmet>
      <Header />
      <section id="slideOne" ref={slideOneRef}>
        <h1>
          <span className="first">{t('aboutSlideOneFirst')}</span> 
          <span className="second">{t('aboutSlideOneSecond')}</span>
        </h1>
      </section>
      <section id="slideTwo" ref={slideTwoRef}>
        <h2 id="slideTwoText" style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}}>
          {t('aboutSlideTwoTitle')}
        </h2>
        <picture>
          <source media="(max-width: 650px)" srcSet="/img/collage-work-mobi.webp"/>
          <img 
            src="/img/collage-work.webp" alt={t('aboutSlideTwoImageAlt')} id="slideTwoImage" width={2560} height={1440} style={{transform: "scale(0)", opacity: 0, filter: "saturate(0.1)"}}
          />
        </picture>
        <h2 id="slideThreeText" style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}}>
          {t('aboutSlideThreeTitle')}
        </h2>
        <picture>
          <source media="(max-width: 650px)" srcSet="/img/dance-mobi.webp"/>
          <img src="/img/dance.webp" alt={t('aboutSlideThreeImageAlt')} id="slideThreeImage" width={2560} height={1440} style={{transform: "scale(0)", opacity: 0, filter: "saturate(0.1)"}}/>
        </picture>

        <h2 id="slideFourText" style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}}>
          {t('aboutSlideFourTitle')}
        </h2>
        <picture>
          <source media="(max-width: 650px)" srcSet="/img/lodowy-szczyt-mobi.webp"/>
          <img src="/img/lodowy-szczyt.webp" alt={t('aboutSlideFourImageAlt')} id="slideFourImage" width={2560} height={1440} style={{transform: "scale(0)", opacity: 0, filter: "saturate(0.1)"}}/>
        </picture>
      </section>
      <section id="slideFive" ref={slideFiveRef}>
        <h2 className="first" style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}}> {t('aboutSlideFiveFirst')}</h2>
        <div id="white" style={{transform: "scale(0)", opacity: 0}}>
          <h2 className="second"style={{transform: "translate(-50%, -50%) scale(5)", opacity: 0}}>{t('aboutSlideFiveSecond')}</h2>
        </div>
      </section>
      <BottomHooks transalteHooksHeading='bottomHooksHeadingAbout' transalteHooksSpan='bottomHooksSpanAbout' />
      <Scroll />
    </>
  )
}