export const translatePath = (path, targetLang) => {
  const translations = {
    '/praca': 'work',
    '/o-mnie': 'about',
    '/po-pracy': 'after-work',
    '/kontakt': 'contact',
    '/polityka-prywatnosci': 'privacy-policy'
  };

  // Normalizuj ścieżkę, usuwając prefix językowy i początkowy ukośnik
  let normalizedPath = path.replace(/^\/(en|pl)/, '').replace(/^\//, '');

  if (targetLang === 'pl') {
    // Dla języka polskiego, odwróć mapowanie
    const reversedTranslations = Object.fromEntries(
      Object.entries(translations).map(([pl, en]) => [en, pl.replace(/^\//, '')])
    );

    normalizedPath = normalizedPath
      .split('/')
      .map(segment => reversedTranslations[segment] || segment)
      .join('/');
    // Upewnij się, że zwracana ścieżka zaczyna się od '/'
    return `/${normalizedPath}`;
  } else {
    normalizedPath = normalizedPath
      .split('/')
      .map(segment => translations[`/${segment}`] || segment)
      .join('/');
    // Upewnij się, że zwracana ścieżka zaczyna się od '/en/'
    return `/en/${normalizedPath}`;
  }
};
