import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import sectionPhoto from '../../../img/futureSectionBg.svg';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function FutureSite() {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  useGSAP(() => {
    const container = containerRef.current;
    ScrollTrigger.refresh();

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        pin: true,
        pinSpacing: true,
        start: "top top",
        end: "+=800px",
        scrub: 1,
        id: "futureSite",
      },
    });
    tl.to("#futureSiteBg", {
      rotation: 720,
      ease: "none",
    });
  }, {scope: containerRef, revertOnUpdate: true});

  return (
    <section id="futureSite" ref={containerRef}>
      <div className="desc">
        <h2 className="title">
          <span className="subTitle">{t('futureSectionSubTitle')}</span> {t('futureSectionTitle')}
        </h2>
        <p>
          {t('futureSectionFirstParagraph')}
        </p>
        <p>{t('futureSectionSecondParagraph')}</p>
      </div>
      <img src={sectionPhoto} alt="Future Site graphic" id="futureSiteBg" width={268} height={248} />
    </section>
  );
}
