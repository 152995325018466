import { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './privacyPolicy.scss';
import { Helmet } from "react-helmet-async";
import { Header } from "./header";
import { BottomHooks } from "./bottomHooks";

export function PrivacyPolicy() {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  useEffect(() => {
    document.body.classList.add('privacyPolicy');
    return () => {
      document.body.classList.remove('privacyPolicy');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>{t('privacyPolicySeoTitle')}</title>
        <meta name="description" content={t('privacyPolicySeoDescription')} />
        <meta property="og:title" content={t('privacyPolicySeoTitle')} />
        <meta property="og:description" content={t('privacyPolicySeoDescription')} />
      </Helmet>
      <Header />
      <section id="article">
        <h1>{isEnglish ? 'Privacy Policy and Cookies — wandachowicz.com' : 'Polityka prywatności i cookies — wandachowicz.com'}</h1>
        <div>
          <h2>{isEnglish ? 'Introduction' : 'Wprowadzenie'}</h2>
          <p>
            {isEnglish
              ? 'Your privacy is very important to us. This Privacy Policy describes what personal data we collect, how we process it and your rights in relation to your personal data. The website is operated by Chris Wandachowicz, a freelance, programmer, graphic, UI/UX designer.'
              : 'Twoja prywatność jest dla nas bardzo ważna. Niniejsza Polityka Prywatności opisuje, jakie dane osobowe zbieramy, jak je przetwarzamy i jakie masz prawa w związku z przetwarzaniem Twoich danych osobowych. Strona internetowa jest prowadzona przez Krzyśka Wandachowicza — freelancer, programista, grafik, UI/UX designer.'
            }
          </p>
          <h2>{isEnglish ? 'Data We Collect' : 'Dane, które zbieramy'}</h2>
          <p>
            {isEnglish
              ? 'Our website does not collect any personal data, except for data collected by Google Analytics for analytical purposes. For more information, please contact Google at '
              : 'Nasza strona internetowa nie zbiera żadnych danych osobowych, z wyjątkiem danych gromadzonych przez Google Analytics w celach analitycznych. Więcej informacji na ten temat udziela firma Google pod adresem: '
            }
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">policies.google.com</a>
          </p>
          <h3>{isEnglish ? 'Google Analytics' : 'Google Analytics'}</h3>
          <p>
            {isEnglish
              ? 'We use Google Analytics to analyze traffic on our website. Google Analytics collects anonymous information such as: browser type and operating system, pages visited on our website, time spent on the site, other statistical data about website usage. This data helps us understand how users use our site, which allows us to improve it. This information is collected using cookies.'
              : 'Korzystamy z Google Analytics, aby analizować ruch na naszej stronie internetowej. Google Analytics zbiera anonimowe informacje, takie jak: rodzaj przeglądarki, system operacyjny, strony odwiedzone na naszej stronie internetowej, czas spędzony na stronie, inne dane statystyczne dotyczące użytkowania strony. Te dane pomagają nam zrozumieć, jak użytkownicy korzystają z naszej strony, co umożliwia nam jej ulepszanie. Informacje te są zbierane za pomocą plików cookie.'
            }
          </p>
          <h2>{isEnglish ? 'Cookies' : 'Pliki Cookies'}</h2>
          <p>
            {isEnglish
              ? 'Our website uses cookies. Cookies are small text files stored on your device by your web browser. We use cookies for: analyzing website traffic (Google Analytics).'
              : 'Nasza strona internetowa wykorzystuje pliki cookie. Pliki cookie to małe pliki tekstowe przechowywane na Twoim urządzeniu przez przeglądarkę internetową. Używamy plików cookie w celu: analizy ruchu na stronie (Google Analytics).'
            }
          </p>
          <h2>{isEnglish ? 'Your Rights' : 'Twoje prawa'}</h2>
          <p>
            {isEnglish
              ? 'Since our website does not collect or store personal data of users, there is no need to exercise rights related to access, correction, deletion, restriction of processing, or objection to the processing of personal data in the context of this site.'
              : 'Ponieważ nasza strona internetowa nie zbiera i nie przechowuje danych osobowych użytkowników, nie ma konieczności korzystania z praw dotyczących dostępu, poprawiania, usuwania, ograniczania przetwarzania czy sprzeciwu wobec przetwarzania danych osobowych w kontekście tej strony.'
            }
          </p>
          <h2>{isEnglish ? 'Changes to the Privacy Policy' : 'Zmiany w Polityce Prywatności'}</h2>
          <p>
            {isEnglish
              ? 'We may update our Privacy Policy as needed. Any changes will be posted on this page. We recommend regularly reviewing this page to stay informed of any changes.'
              : 'Możemy aktualizować naszą Politykę Prywatności w miarę potrzeb. Wszelkie zmiany będą publikowane na tej stronie. Zalecamy regularne przeglądanie tej strony, aby być na bieżąco z wszelkimi zmianami.'
            }
          </p>
          <h2>{isEnglish ? 'Contact' : 'Kontakt'}</h2>
          <p>
            {isEnglish
              ? 'If you have any questions about our Privacy Policy, please contact us at '
              : 'Jeśli masz jakiekolwiek pytania dotyczące naszej Polityki Prywatności, prosimy o '
            }
            <Link to={isEnglish ? `/en/contact` : `/kontakt`} className='contact'>{isEnglish ? `contact.` : `kontakt.`}</Link>
          </p>
        </div>
      </section>
      <BottomHooks transalteHooksHeading='bottomHooksHeadingPrivacyPolicy' transalteHooksSpan='bottomHooksSpanPrivacyPolicy' />
    </>
  )
}