import React, { useEffect, useState, useRef } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import './afterWork.scss';
import { Header } from "./header";
import { BottomHooks } from "./bottomHooks";
import { Scroll } from "../scroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export function AfterWork() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [images, setImages] = useState([]);
  const galleryRef = useRef(null);

  const splitTextByEN = (text) => {
    const separatorIndex = text.indexOf("EN:");
    if (separatorIndex !== -1) {
      const plText = text.slice(0, separatorIndex).trim(); // Część w języku polskim
      const enText = text.slice(separatorIndex + 3).trim(); // Część w języku angielskim (po "EN:")
      return { pl: plText, en: enText };
    } else {
      // Jeśli nie ma znaku "EN:", zwracamy cały tekst w języku polskim
      return { pl: text.trim(), en: "" };
    }
  };

  useEffect(() => {
    document.body.classList.add('afterWork');
    return () => {
      document.body.classList.remove('afterWork');
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/img/after-work/gallery.json");
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchData();
  }, []);

  useGSAP(() => {
      if(images) {
        
        const gallery = galleryRef.current;
        ScrollTrigger.refresh();
        const tl = gsap.timeline({
          transform: "translate(0px, 0px)",
          scrollTrigger: {
            trigger: gallery,
            pin: true,
            pinSpacing: true,
            scrub: 0.1,
            start: "top top",
            end: "+=4100%",
            // end: () => "+=" + galleryRef.offsetHeight,
          },
        });
      
        // Animacje dla wszystkich elementów w galerii
        images.forEach((image, index) => {
          tl.to(`#imageTitle-${index}`, {
            opacity: 1,
            scale: 1.3,
            zIndex: 2,
            duration: 0.5,
            ease: "power3.out"
          });
          tl.to(`#imageTitle-${index}`, {
            opacity: 0,
            scale: 6,
            zIndex: 1,
            duration: 0.5,
            ease: "power3.out"
          }, "-=0.1");
          tl.to(`#image-${index}`, {
            opacity: 1,
            scale: 1,
            filter: "saturate(1)",
            zIndex: 2,
            duration: 0.5,
            ease: "power3.out"
          });
          tl.to(`#image-${index}`, {
            opacity: 0,
            scale: 2,
            filter: "saturate(0.1)",
            zIndex: 1,
            duration: 0.5,
            ease: "power3.out"
          }, "-=0.1");
        });
      }
    },
    { 
      scope: galleryRef,
      revertOnUpdate: true,
      dependencies: [images] 
    }
  );

  return (
    <>
      <Helmet>
        <title>{t('afterWorkSinglePageSeoTitle')}</title>
        <meta name="description" content={t('afterWorkSinglePageSeoDescription')} />
        <meta property="og:title" content={t('afterWorkSinglePageSeoTitle')} />
        <meta property="og:description" content={t('afterWorkSinglePageSeoDescription')} />
      </Helmet>
      <Header />
      <section id="heroSection">
        <h1 className="title">
          <span className="subTitle">{t('afterWorkPageSubTitle')}</span> {t('afterWorkPageTitle')}
        </h1>
        <img src="/img/after-work/1-cover.webp" alt={t('afterWorkCoverPhotoAlt')} width={1706} height={1280} />
      </section>
      <section id="gallery" ref={galleryRef}>
        {images.map((image, index) => {
          const altText = splitTextByEN(image.alt);
          const alt = altText[language] || altText.pl; // Wybierz odpowiednią część tekstu na podstawie języka
          return (
            <React.Fragment key={index}>
              <h2 id={`imageTitle-${index}`} style={{ transform: "translate(-50%, -50%) scale(0)", opacity: 0 }}>
                {alt}
              </h2>
              <picture>
                <source media="(max-width: 650px)" srcSet={`/img/after-work/${image.srcMobile}`}/>
                <img
                  key={index}
                  id={`image-${index}`}
                  src={`/img/after-work/${image.src}`}
                  alt={alt}
                  width={image.width}
                  height={image.height}
                  style={{transform: "scale(0)", opacity: 0, filter: "saturate(0.1)"}}
                />
              </picture>
            </React.Fragment>
          );
        })}
      </section>

      <BottomHooks transalteHooksHeading='bottomHooksHeadingAfterWork' transalteHooksSpan='bottomHooksSpanAfterWork' />
      <Scroll />
    </>
  )
}