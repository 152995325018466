import { useTranslation } from "react-i18next";
import { BlobHS } from "./BlobHS";

export function HeroSection() {
  const { t } = useTranslation();
  return (
    <section id="heroSection">
      <h2>
        <span className="subTitle">{t('heroSectionSubTitle')}</span> {t('heroSectionTitle')}
      </h2>
      <BlobHS />
    </section>
  )
}