import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function LanguagesSection() {
  const { t } = useTranslation();
  const containerRef = useRef();

  useGSAP(() => {
    const languageSection = containerRef.current;
    const secondBG = document.getElementById('secondBG');
    ScrollTrigger.refresh();
    gsap.to(secondBG, {
      scrollTrigger: {
        trigger: languageSection,
        start: "top top",
        end: "+=400px",
        scrub: 4,
        pin: true,
        pinSpacing: true,
        id: "langPin",
      },
      x: 0,
    });
  }, {scope: containerRef, revertOnUpdate: true});

  return (
    <section id="languages" ref={containerRef}>
      <div className="desc">
        <h3 className="question">{t('languagesSectionQuestion')}</h3>
        <h3 className="answer">{t('languagesSectionSubAnswer')}</h3>
      </div>
      <div id="firstBG"></div>
      {/* <div id="secondBG" style={{backgroundColor: '#D8CDD8', transform: "translateX(-100%)"}}></div> */}
      <div id="secondBG" style={{transform: "translateX(-100%)"}}></div>
    </section>
  )
}
