import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './notFound.scss';
import { Helmet } from "react-helmet-async";
import { Header } from "./header";
import { BottomHooks } from "./bottomHooks";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export function NotFound() {
  const { t } = useTranslation();
  const heroSectionRef = useRef(null);

  useEffect(() => {
    document.body.classList.add('notFound');
    return () => {
      document.body.classList.remove('notFound');
    };
  }, []);

  useGSAP(() => {
    // const mm = gsap.matchMedia();
    gsap.to("h1", {
      opacity: 1,
      scale: 1.4,
      duration: 4,
      delay: 1,
      ease: "power3.out",
    });
    gsap.to("h1", {
      opacity: 0,
      scale: 0,
      duration: 2,
      delay: 0,
      ease: "power3.out",
    }, "-=0.1");
    gsap.to("h2", {
      opacity: 1,
      scale: 1.4,
      duration: 4,
      delay: 0,
      ease: "power3.out",
    }, "-=0.1");
    gsap.to("h2", {
      opacity: 0,
      scale: 0,
      duration: 2,
      delay: 1,
      ease: "power3.out",
    }, "-=0.1");
    gsap.to("a", {
      opacity: 1,
      scale: 1.4,
      duration: 4,
      delay: 0,
      ease: "power3.out",
    }, "-=0.1");
    
  }, {scope: heroSectionRef});

  return (
    <>
      <Helmet>
        <title>{t('notFoundSeoTitle')}</title>
        <meta name="description" content={t('notFoundSeoDescription')} />
        <meta property="og:title" content={t('notFoundSeoTitle')} />
        <meta property="og:description" content={t('notFoundSeoDescription')} />
      </Helmet>
      <Header />
      <section id="heroNotFound" ref={heroSectionRef}>
        <h1 style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}}>{t('notFoundHeadingOne')}</h1>
        <h2 style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}}>{t('notFoundHeadingTwo')}</h2>
        <Link style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}} to='/'>{t('notFoundLink')}</Link>
      </section>
      <BottomHooks transalteHooksHeading='bottomHooksHeadingNotFound' transalteHooksSpan='bottomHooksSpanNotFound' />
    </>
  )
}