import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export function Footer() {
  const currentYear = new Date().getFullYear();
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  return (
    <footer>
      <div id="bottom">
        <p>{t('footerCopyright')} {currentYear} wandachowicz.com</p>
        <Link to={isEnglish ? `/en/privacy-policy` : `/polityka-prywatnosci`}>{t('footerLinkPrivacyPolicy')}</Link>
      </div>
    </footer>
  )
}