import './i18next';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import { Home } from './components/site/home';
import { About } from './components/site/about';
import { Works } from './components/site/works'; 
import { Work } from './components/site/works/work';
import { AfterWork } from './components/site/afterWork';
import { Contact } from './components/site/contact';
import { PrivacyPolicy } from './components/site/privacyPolicy';
import { NotFound } from './components/site/notFound';
import { Helmet } from 'react-helmet-async';

export default function App() {
  const { i18n } = useTranslation();

  // Mapa języków na locale Open Graph
  const localeMap = {
    pl: 'pl_PL',
    en: 'en_US',
  };
  // Znajdź odpowiedni locale, jeśli język nie istnieje w mapie, ustaw domyślny
  const ogLocale = localeMap[i18n.language] || 'en_US';

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
      <>
        <Helmet>
          <meta property="og:locale" content={ogLocale} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="UI/UX desing, web developer, programmer - Krzysiek Wandachowicz" />
          <meta property="og:image" content="https://wandachowicz.com/img/wandachowicz_com.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="675" />
          <meta property="og:image:type" content="image/png" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/o-mnie' element={<About />} />
          <Route path='/praca' element={<Works />} />
          <Route path='/praca/:slug' element={<Work />} />
          <Route path='/po-pracy' element={<AfterWork />} />
          <Route path='/kontakt' element={<Contact />} />
          <Route path='/polityka-prywatnosci' element={<PrivacyPolicy />} />

          <Route path="/en/" element={<Home />} />
          <Route path='/en/about' element={<About />} />
          <Route path='/en/work' element={<Works />} />
          <Route path='/en/work/:slug' element={<Work />} />
          <Route path='/en/after-work' element={<AfterWork />} />
          <Route path='/en/contact' element={<Contact />} />
          <Route path='/en/privacy-policy' element={<PrivacyPolicy />} />
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
  );
}
