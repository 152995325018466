// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import langPL from './lang/pl.json';
import langEN from './lang/en.json';

const resources = {
  pl: {
    translation: langPL,
  },
  en: {
    translation: langEN,
  },
};

// Funkcja do konwertowania pełnych kodów językowych na krótsze wersje
const getShortLang = (lang) => {
  if (lang.includes('-')) {
    return lang.split('-')[0];
  }
  return lang;
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    // Ustawienie krótkiego kodu językowego po inicjalizacji
    const initialLang = getShortLang(i18n.language);
    i18n.changeLanguage(initialLang);
  });

// Konwertuj pełne kody językowe na krótsze wersje przy zmianie języka, jeśli to konieczne
i18n.on('languageChanged', (lang) => {
  const shortLang = getShortLang(lang);
  if (lang !== shortLang) {
    i18n.changeLanguage(shortLang);
  }
});

export default i18n;
