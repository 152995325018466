import { useEffect } from "react";
import './home.scss';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Header } from './header';
import { HeroSection } from './home/heroSection';
import { FutureSite } from './home/futureSite';
import { AboutMe } from './home/aboutMe';
import { LanguagesSection } from './home/languagesSection';
import { Competence } from './home/competence';
import { WorkSection } from './home/workSection';
import { BottomHooks } from "./bottomHooks";
// import { Scroll } from "../scroll";

export function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add('home');
    return () => {
      document.body.classList.remove('home');
    };
  }, []);
  

  return (
    <>
      <Helmet>
        <title>{t('homePageSeoTitle')}</title>
        <meta name="description" content={t('homePageSeoDescription')} />

        <meta property="og:title" content={t('homePageSeoTitle')} />
        <meta property="og:description" content={t('homePageSeoDescription')} />
      </Helmet>
      <Header />
      <HeroSection />
      <FutureSite />
      <AboutMe />
      <LanguagesSection />
      <Competence />
      <WorkSection />
      <BottomHooks transalteHooksHeading='bottomHooksHeadingHome' transalteHooksSpan='bottomHooksSpanHome' />
      {/* <Scroll /> */}
    </>
  )
}