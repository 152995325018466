import { useRef, useEffect, useState } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Footer } from "./footer";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function BottomHooks({ transalteHooksHeading, transalteHooksSpan }) {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const hooksContainer = useRef(null);
  const [showProjectsLink, setShowProjectsLink] = useState(null);

  useGSAP(() => {
    const bottomHooksSection = hooksContainer.current;

    ScrollTrigger.refresh();

    const tlBH = gsap.timeline({
      scrollTrigger: {
        trigger: bottomHooksSection,
        start: "top +=100px",
        end: "bottom bottom",
        toggleActions: "play none resume none",
      },
    });
    tlBH.to("h3", { scale: 1, opacity: 1, duration: 3, delay: 0.5, ease: "power1.out" });
    tlBH.to("h4", { scale: 1, opacity: 1, duration: 2, delay: 0.1, ease: "power4.out" });

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, { scope: hooksContainer, revertOnUpdate: true });

  useEffect(() => {
    if (document.body.classList.contains("project")) {
      setShowProjectsLink(
        <Link to={isEnglish ? `/en/work` : `/praca`} className='showProjects more'>
          {t('bottomHooksShowProjectsIfWorkSite')}
        </Link>
      );
    } else {
      setShowProjectsLink(null);
    }
  }, [isEnglish, t]);

  return (
    <>
      <section id="bottomHooks" ref={hooksContainer}>
        <div className="container">
          <h3 style={{ transform: "scale(0)", opacity: 0 }}>{t(transalteHooksHeading)}</h3>
          <h4 style={{ transform: "scale(0)", opacity: 0 }}>{t(transalteHooksSpan)}</h4>
        </div>
        {showProjectsLink}
      </section>
      <Footer />
    </>
  );
}
