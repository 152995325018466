import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { translatePath } from './pathMappings';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (lang) => {
    const newPath = translatePath(location.pathname, lang);
    i18n.changeLanguage(lang);
    navigate(newPath);
  };

  const getShortLang = (lang) => {
    if (lang.includes('-')) {
      return lang.split('-')[0];
    }
    return lang;
  };

  const isActive = (lang) => getShortLang(i18n.language) === lang ? 'activeLang' : '';

  return (
    <div id='langSwitcher'>
      <button onClick={() => changeLanguage('pl')} className={isActive('pl')}>PL</button>/
      <button onClick={() => changeLanguage('en')} className={isActive('en')}>EN</button>
    </div>
  );
}
