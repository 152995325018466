import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

export const BlobHS = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const mount = mountRef.current;

    // Scena, kamera i renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, mount.clientWidth / mount.clientHeight, 0.1, 100);

    const updateCameraPosition = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      const heightBelow650px = window.innerHeight < 650;

      if (isLandscape && heightBelow650px) {
        // Jeśli jest w orientacji landscape i wysokość ekranu jest poniżej 650px
        if (window.innerWidth < 992) {
          camera.position.x = -2.5;
          camera.position.z = 4.1;
        } else {
          camera.position.x = -1.5;
          camera.position.z = 4.3;
        }
      } else {
        if (window.innerWidth < 992) {
          camera.position.x = 0;
          camera.position.z = 4.9;
        } else {
          camera.position.x = -1.5;
          camera.position.z = 4.3;
        }
      }
    };

    updateCameraPosition();

    const handleResize = () => {
      camera.aspect = mount.clientWidth / mount.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(mount.clientWidth, mount.clientHeight);

      updateCameraPosition();
    };

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(mount.clientWidth, mount.clientHeight);
    mount.appendChild(renderer.domElement);
    window.addEventListener('resize', handleResize);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
    scene.add(ambientLight);

    // Uzupełnione shadery
    const shaderMaterial = new THREE.ShaderMaterial({
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color;
        uniform float opacity;
        varying vec2 vUv;
        void main() {
          gl_FragColor = vec4(color * vUv.x, opacity);
        }
      `,
      uniforms: {
        color: { value: new THREE.Color(0xefefef) },
        opacity: { value: 0.1 }
      },
      transparent: true,
    });

    const geometry = new THREE.SphereGeometry(1, 64, 64);
    const blob = new THREE.Mesh(geometry, shaderMaterial);
    scene.add(blob);

    let lastTime = 0;
    const animate = (time) => {
      requestAnimationFrame(animate);
      let deltaTime = time - lastTime;
      lastTime = time;

      blob.rotation.z += 0.00001 * deltaTime;
      blob.scale.x = 3 + 0.1 * Math.sin(Date.now() * 0.002);
      blob.scale.y = 2.49 + 0.1 * Math.cos(Date.now() * 0.001);

      renderer.render(scene, camera);
    };

    animate(0);

    return () => {
      mount.removeChild(renderer.domElement);
      scene.remove(blob);
      geometry.dispose();
      shaderMaterial.dispose();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div id='blob' ref={mountRef} style={{ width: '100%', height: '100%' }} />;
};
