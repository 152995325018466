import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import firstImage from '../../../img/firts-photo.webp';
import secondImage from '../../../img/second-photo.webp';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function AboutMe() {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const containerRef = useRef(null);

  useGSAP(() => {
    const container = containerRef.current;
    const firstImage = document.getElementById("firstImage");
    const secondImage = document.getElementById("secondImage");
    ScrollTrigger.refresh();

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        pin: true,
        pinSpacing: true,
        start: "top top",
        end: "+=600px",
        scrub: 2,
        id: "about",
      },
    });
    tl.to(firstImage, {
      opacity: 0.1,
    });
    tl.to(secondImage, {
      opacity: 1,
    });
  }, {scope: containerRef, revertOnUpdate: true,});

  return (
    <section id="aboutMe" ref={containerRef}>
      <div className="desc">
        <h1 className="title">
          <span className="subTitle">{t('aboutMeSectionSubTitle')}</span> {t('aboutMeSectionTitle')}
        </h1>
        <p>{t('aboutMeSectionFirstParagraph')}</p>
        <p>
          {t('aboutMeSectionSecondParagraph')}
        </p>
      </div>
      <Link to={isEnglish ? `/en/about` : `/o-mnie`} className='more'>{t('aboutMeSectionLink')}</Link>
      <div id="images">
        <img id="firstImage" src={firstImage} alt={t('aboutMeSectionFirstImageAlt')} width={535} height={828} style={{ opacity: 1 }}/>
        <img id="secondImage" src={secondImage} alt={t('aboutMeSectionSecondImageAlt')} width={535} height={828} style={{ opacity: 0 }}/>
      </div>
    </section>
  );
}